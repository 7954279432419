*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
}

html > #root > div {
  width: 100vh;
  height: 100vh;
}

html {
  height: 100vh;
}

body {
  position: relative;
  min-height: 100%;
  min-height: 100vh;
  border-bottom: .0000000000000000000000000000000000000001px transparent solid;

}
